.free {
  position: relative;
  margin: 5rem 0;
  .container {
    display: flex;
    background-color: var(--orange);
    position: relative;
    height: 25rem;
    border-radius: 1rem;
    overflow: hidden;
    z-index: 1;
    .background {
      z-index: -1;
      .ellipse {
        position: absolute;
        height: 100%;
        width: 28rem;
        border-radius: 100%;
        filter: blur(100px);
      }
      .pink {
        top: -10%;
        left: -15%;
        background-color: var(--pink);
      }
      .green {
        top: -10%;
        right: -15%;
        background-color: var(--green);
      }
    }
    .content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding-top: 3rem;
      padding-left: 5rem;
      font-size: 1.3rem;
      .image {
        img {
        }
      }
      .title {
        font-size: 2rem;
      }
      .description {
      }
      
      /* Add this CSS for the social-icons class */
      .social-icons {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #ffffff; /* Set the color for social icons */
      }

      /* Style the social icons */
      .social-icons {
        color: #000; /* Initial color */
        transition: color 0.3s ease; /* Add a transition for smooth color change */

        /* Define hover effect */
        &:hover {
          color: #fff; /* Change the color to white on hover */
        }
      }

      .twitter-icon {
        color: white;
      }
      /* Add hover effect to Twitter icon */
      .twitter-icon:hover {
        color: rgb(37, 15, 231); /* Change the color on hover (e.g., to green) */
      }

    }
  }
  .cards {
    display: flex;
    position: absolute;
    top: -2rem;
    right: 40%;
    .card1 {
      top: 0;
      position: absolute;
      transform: rotate(-10deg);
      z-index: 3;
      font-size: 0.6rem;
      .card-image {
        img {
          height: 10rem;
          width: 12rem;
        }
      }
    }
    .card2 {
      top: 0;
      z-index: 2;
      position: absolute;
      left: 10rem;
      transform: rotate(5deg);
      font-size: 0.7rem;
      .card-image {
        img {
          height: 10rem;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin: 0;
    .container {
      padding: 1rem 2rem;
      height: 40vh;
      border-radius: 0;
      .background {
        .pink {
          left: -70%;
        }
        .green {
          right: -100%;
        }
      }
      .content {
        padding: 0;
        font-size: 16px;
        gap: 1rem;
        .title {
          font-size: 22px;
        }
      }

    }
    .cards {
      .card1,
      .card2 {
        zoom: 0.3;
      }
    }
  }
}
